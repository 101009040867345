














































































import { Nullable } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const RoomsStore = namespace('RoomsStore')
import { RoomModel } from '@/modules/MyRoomsPage/models/RoomModel'
import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'
import BaseTimepicker from '@/components/forms/BaseTimepicker.vue'
import BaseDatepicker from '@/components/forms/BaseDatepicker.vue'
import { required, helpers, ValidationRule } from 'vuelidate/lib/validators'
import BaseSelectNew from '@/components/forms/BaseSelectNew/BaseSelect.vue'

const dateCompare = (): ValidationRule => {
  return helpers.withParams(
    { type: 'dateCompare' },
    // eslint-disable-next-line
    function (value: string, vm: any) {
      const from = Date.parse(`${vm?.dateFrom}T${vm?.timeFrom}`)
      const to = Date.parse(`${vm?.dateTo}T${vm?.timeTo}`)
      return isNaN(from) || isNaN(to) || (from > 0 && to > 0 && from < to)
    }
  )
}

const validations = {
  roomId: { required },
  dateFrom: { required, dateCompare: dateCompare() },
  timeFrom: { required, dateCompare: dateCompare() },
  dateTo: { required, dateCompare: dateCompare() },
  timeTo: { required, dateCompare: dateCompare() },
}

export interface BookingFormInterface {
  id?: number
  roomId?: number
  dateFrom?: string
  timeFrom?: string
  dateTo?: string
  timeTo?: string
}

@Component({
  name: 'BookingForm',
  components: {
    BaseSelectNew,
    FormGroup,
    Field,
    BaseTimepicker,
    BaseDatepicker,
  },
  validations,
})
export default class extends Vue {
  @Prop({ required: true })
  value!: BookingFormInterface

  @RoomsStore.Getter
  public roomList!: RoomModel[]

  id?: number
  roomId: string | number = ''
  dateFrom: Nullable<string> = null
  timeFrom = ''
  dateTo: Nullable<string> = null
  timeTo = ''

  created(): void {
    this.id = this.value.id
    this.roomId = this.value.roomId || ''
    this.dateFrom = this.value.dateFrom || null
    this.timeFrom = this.value.timeFrom || '00:00'
    this.dateTo = this.value.dateTo || null
    this.timeTo = this.value.timeTo || '00:00'
  }

  public update(): void {
    this.$emit('input', {
      id: this.id,
      roomId: this.roomId,
      dateFrom: this.dateFrom,
      timeFrom: this.timeFrom,
      dateTo: this.dateTo,
      timeTo: this.timeTo,
    })
  }

  public checkValidity(): boolean {
    this.$v.$touch()

    return !this.$v.$anyError
  }
}
