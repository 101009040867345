




































































import { Nullable } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const BookingStore = namespace('BookingStore')
import { BookingModel } from '@/core/models/BookingModel'
import BookingService from '@/core/services/BookingService'

import BookingForm, { BookingFormInterface } from './BookingForm.vue'
import Overlay from '@/components/Overlay.vue'
import Modal from '@/components/Modal.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import Spinner from '@/components/Spinner.vue'

export interface AddPremiumInterface {
  paymentMethod: number
  roomId: Nullable<number>
  planId: Nullable<number>
  offers: number[]
}

@Component({
  name: 'EditBooking',
  components: {
    Overlay,
    Modal,
    BaseIcon,
    BaseButton,
    Spinner,
    BookingForm,
  },
})
export default class extends Vue {
  @Prop({ required: true })
  value!: BookingFormInterface

  showEditBooking = true
  showErrorModal = false

  errorMessage = ''
  loading = false

  @BookingStore.Action
  private addBooking!: (payload: {
    placeId: number
    start: Date
    end: Date
  }) => Promise<BookingModel>

  @BookingStore.Action
  private updateBooking!: (payload: {
    id: number
    placeId: number
    start: Date
    end: Date
  }) => Promise<BookingModel>

  async submit(): Promise<void> {
    if ((this.$refs.form as any).checkValidity() && this.value) {
      this.loading = true
      try {
        if (this.value.id) {
          await this.updateBooking({
            id: this.value.id,
            placeId: this.value.roomId as number,
            start: new Date(`${this.value.dateFrom}T${this.value.timeFrom}:00`),
            end: new Date(`${this.value.dateTo}T${this.value.timeTo}:00`),
          })
        } else {
          await this.addBooking({
            placeId: this.value.roomId as number,
            start: new Date(`${this.value.dateFrom}T${this.value.timeFrom}:00`),
            end: new Date(`${this.value.dateTo}T${this.value.timeTo}:00`),
          })
        }
        this.loading = false
        this.$emit('changed')
        this.$emit('close')
      } catch (error) {
        this.loading = false
        this.showEditBooking = false
        this.errorMessage = ''
        if (BookingService.isServiceError(error)) {
          const errors = error.response?.data?.errors || []
          this.errorMessage = errors[0] || error.message
        }
        this.errorMessage = this.errorMessage || 'Неизвестная ошибка'
        this.showErrorModal = true
      }
    }
  }
}
